<script setup lang="ts">
    export interface SwiperNavigationProps {
        isBeginning?: boolean;
        isEnd?: boolean;
        link?: Link;
        swipeLeft: (event: MouseEvent) => void;
        swipeRight: (event: MouseEvent) => void;
        theme?: 'wider';
    }

    const props = defineProps<SwiperNavigationProps>();

    const canSwipe = computed(() => {
        return !(props.isBeginning && props.isEnd);
    });
</script>

<template>
    <client-only>
        <div
            v-if="canSwipe || link"
            class="mol-swiper-navigation col-span-2 mx-auto mt-4 flex items-center justify-center md:col-span-12">
            <lazy-atm-swiper-navigation-button
                v-show="canSwipe"
                :class="[
                    'max-lg:hidden',
                    {
                        'lg:left-0 lg:-translate-x-1/2 2xl:-left-8 2xl:-translate-x-full': theme === 'wider',
                        'lg:left-8': theme !== 'wider',
                    },
                ]"
                :button-disabled="isBeginning"
                :theme="theme"
                swipe-direction="left"
                :aria-label="$t('cta.slider.previous')"
                @click="swipeLeft" />
            <lazy-atm-link
                v-if="link"
                :link="link"
                text-link
                class="mx-3 text-center" />
            <lazy-atm-swiper-navigation-button
                v-show="canSwipe"
                :class="[
                    'max-lg:hidden',
                    { 'lg:-right-0 lg:translate-x-1/2 2xl:-right-8 2xl:translate-x-full': theme === 'wider', 'lg:right-8': theme !== 'wider' },
                ]"
                :button-disabled="isEnd"
                :theme="theme"
                swipe-direction="right"
                :aria-label="$t('cta.slider.next')"
                @click="swipeRight" />
        </div>
    </client-only>
</template>
